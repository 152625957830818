body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.h-screen-70 {
  height: 70vh;
}

.pageLoader div {
  animation-duration: 0.5s;
}

.pageLoader div:first-child {
  animation-delay: 0.1s;
}

.mask-loader {
  border: 4px solid #ddd;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pageLoader div:nth-child(2) {
  animation-delay: 0.3s;
}

.pageLoader div:nth-child(3) {
  animation-delay: 0.6s;
}

.landingPage-header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.landingPage-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 80%;
  margin: 0 auto;
}

.landingPage-header-logo {
  width: 200px;
  height: auto;
}

.landingPage-heroSection {
  width: 100%;
}

.landingPage-heroSection-container {
  width: 80%;
  height: 90vh;
  margin: 0 auto;
  display: grid;
  align-items: center;
  gap: 10%;
  grid-template-columns: 50% 40%;
}

.landingPage-heroSection-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  margin-bottom: 20px;
}

.landingPage-heroSection-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 25px;
}

.landingPage-header-menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landingPage-header-menuOption {
  margin: 0 15px;
  text-decoration: none;
  cursor: pointer;
}

.landingPage-header-menuOption:hover {
  color: #2f54eb !important;
}

.landingPage-featuresOverview {
  width: 100%;
  padding: 50px 0;
  background: #f5f5f5;
}

.landingPage-featuresOverview-container {
  width: 80%;
  margin: 0 auto;
}

.landingPage-featuresOverview-title {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
}

.landingPage-featuresOverview-features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%;
}

.landingPage-featuresOverview-feature {
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3% 0;
}

.landingPage-featuresOverview-feature-title {
  font-size: 16px;
  font-weight: 700;
  margin: 25px 0 15px 0;
  text-align: center;
  line-height: 24px;
}

.landingPage-featuresOverview-feature-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.landingPage-features {
  width: 100%;
  padding: 0 0 50px 0;
}

.landingPage-features-container {
  width: 80%;
  margin: 0 auto;
}

.landingPage-features-title {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
}

.landingPage-features-features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%;
}

.landingPage-features-feature-imageContainer,
.landingPage-features-feature-image {
  width: 100%;
  height: 100%;
}

.landingPage-features-feature-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landingPage-features-feature {
  display: grid;
  grid-template-columns: 40% 40%;
  gap: 20%;
  margin: 4% 0;
}

.landingPage-features-feature-title {
  font-size: 50px;
  font-weight: 700;
  margin: 25px 0 15px 0;
  line-height: 64px;
}

.landingPage-features-feature-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

.landingPage-contact {
  background: #f5f5f5;
}

.landingPage-contact-content {
  padding: 50px;
  width: 80%;
  margin: 0 auto;
}

.landingPage-contact-input-group {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin: 40px 0 15px 0;
}

.landingPage-contact-input {
  min-height: 60px;
  border: none;
}

.landingPage-contact-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.landingPage-contact-copyRight {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 19px;
}

.main-content {
  margin: 0 16px 16px 16px;
  position: relative;
  overflow: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
}

.main-content::-webkit-scrollbar {
  display: none;
}

.signIn {
  margin-left: 5px;
  color: #1677ff;
  cursor: pointer;
}

.pb-30 {
  padding: 0 0 33rem 0;
}

.gray-divider {
  color: #CECCCC;
}

.ant-menu-item-icon {
  height: 20px;
  width: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-label {
  font-size: 14px;
  margin-bottom: 6px;
  color: #666;
}

.input-err-msg {
  color: red;
  margin: 3px 0 0;
}

.input-mandatory {
  color: red;
  margin: 0 0 0 2px;
}

.create-footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data-img {
  width: 60vh;
  height: auto;
}

.broken-page-container {
  display: flex;
  height: 100vh;
}

.align-items-start {
  align-items: flex-start;
}

.broken-page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 30px;
}

.broken-page-img-container {
  height: auto;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 30px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
}

.login-img-container {
  width: 50%;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.broken-image-content {
  padding: 30px;
}

.forgot-password {
  height: 93vh;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.forgot-password-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.enquiry-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 450px;
}

.select-group-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.select-group-container::-webkit-scrollbar {
  display: none;
}

.select-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-right: 20px;
}

.select-container:last-child {
  margin: 0;
}

.select-width {
  width: 100%;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}

.select-tag {
  padding: 5px 15px;
  border-radius: 20px;
  background: #fff;
}
.c-pointer {
  cursor: pointer;
}
.create-page-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.create-page-section {
  width: 100%;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.gap-1 {
  gap: 10px;
}
.gap-2 {
  gap: 20px;
}
.gap-3 {
  gap: 30px;
}
.chit-group-overview-customer-value:last-child {
  border-bottom: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}

.ant-layout .ant-layout-sider-zero-width-trigger {
  top: 12px;
  z-index: 10;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-timeline .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0;
}

.text-center {
  text-align: center;
}

.danger {
  color: red;
}

.search-loading {
  padding-left: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
}

.page-loading {
  height: 86vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-loading {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-split {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.modal-footer {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.notification-action-container {
  margin: 40px auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}

.header-user-name {
  line-height: 22px;
  font-weight: 500;
}

.header-role-name {
  line-height: 22px;
  color: #666;
}

.chitgroup-overview_data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.chitgroup-overview_data-value {
  font-weight: 600;
}

.chitgroup-overview_table {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.chitgroup-overview_key {
  padding: 6px;
  margin: 0;
  height: 30px;
  display: flex;
  align-items: center;
  background: #eee;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.chitgroup-overview_key-customer {
  background: #ddd;
}

.chitgroup-overview_value {
  padding: 6px 4px;
  margin: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 125px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.chitgroup-overview_value_not-received {
  color: #fff;
  background: #e96479;
}
.chitgroup-overview_value_delayed {
  background: #fad4d4;
}
.text-gray {
  color: #00000073;
}
.bb-0 {
  border-bottom: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

.dashboard {
  margin: 20px 0;
}
.dashboard-numbers {
  width: 100%;
  align-items: flex-start;
}
.dashboard-report__container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 4% 0;
}
.dashboard-report {
  width: 49%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.ant-timeline .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0 !important;
}

.dashboard-numbers {
  .ant-card {
    min-height: 171px;
  }
  .ant-space-item {
    width: 24%;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.publicCustomerTransactionList {
  .ant-card-body {
    max-height: 400px;
    overflow: scroll;
  }
}

.userConfig__data-card {
  min-width: 300px;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.massDownloadInfo {
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.ant-upload-select {
  width: 100%;
}

@media screen and (max-width: 1098px) {
  .landingPage-heroSection-container {
    grid-template-columns: 40% 60%;
  }
  .landingPage-heroSection-imgContainer {
    width: 100%;
  }
  .landingPage-heroSection-img {
    width: 100%;
    height: auto;
  }
  .landingPage-featuresOverview-feature {
    width: 40%;
  }
  .landingPage-featuresOverview-title {
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 798px) {
  .ant-tour {
    width: 96%;
  }
  .dashboard-numbers {
    .ant-space-item {
      width: 100%;
    }
    .ant-card-head {
      padding: 5px 8px;
      min-height: auto;
    }
    .ant-card-body {
      padding: 5px 8px;
    }
  }
  .landingPage-header-container {
    width: 96%;
  }
  .landingPage-heroSection-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 15%;
  }
  .landingPage-featuresOverview-container {
    width: 90%;
  }
  .landingPage-featuresOverview-feature {
    width: 100%;
    margin: 10% 0;
  }
  .landingPage-features-feature {
    display: flex;
    flex-direction: column;
    gap: 10%;
    margin: 25% 0;
  }
  .landingPage-features-feature-title {
    font-size: 30px;
    font-weight: 700;
    margin: 25px 0 15px 0;
    line-height: 44px;
  }

  .landingPage-features-feature-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }
  .landingPage-contact-content {
    width: auto;
  }
  .landingPage-contact-input-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .enquiry-content {
    width: 96%;
  }
  .broken-page-img-container,
  .login-img-container {
    display: none;
  }
  .broken-image-content {
    padding: 0;
  }
  .login-form {
    width: 98%;
  }
  .no-data-img {
    width: auto;
    height: auto;
  }
  .select-container {
    width: auto;
  }
  .payment-paid-status-select-container {
    min-width: 150px;
  }
  .create-page-container {
    flex-direction: column;
    gap: 5px;
  }
  .page-loading {
    height: 80vh;
  }
  .modal-loading {
    height: 50vh;
  }
  .modal-split {
    flex-direction: column;
    gap: 0;
  }
  .modal-footer {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .modal-footer-action {
    width: 100%;
    margin-top: 12px;
  }
  .select-width-installment {
    width: 150px;
  }
  .chitgroup-overview_data {
    gap: 5px;
    flex-direction: column;
  }
  .dashboard-report {
    width: 100%;
  }
  .dashboard-report__container {
    gap: 40px;
    padding: 40px 0;
  }
  .userConfig__data-card {
    width: 100%;
  }
}

.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.text-left {
  text-align: left;
}
.h-0 {
  height: 0;
}
